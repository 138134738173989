@charset "UTF-8";
@import 'foundation/foundation';
@import "vendor/font/icons";
@import "layout/variable";
// --------------------------------------------------------
/* Container Style*/
// --------------------------------------------------------

.sec {
    &-title__block {
        padding-top: rem-calc(90);
        padding-bottom: 0;
        @include breakpoint(large down) {
            padding-top: rem-calc(30); } }
    &-title__h1 {
        font-size: rem-calc(18);
        color: $textColorO_7;
        font-weight: normal;
        @include breakpoint(medium down) {
            font-size: rem-calc(14); } }
    &-kv-banner {
        padding: rem-calc(15) 0 rem-calc(46);
        @include breakpoint(medium down) {
            padding: rem-calc(15) rem-calc(15) rem-calc(18); }

        .slideshow {
            &.is-prevent {
                pointer-events: none;

                .swiper-arrow,
                .swiper-pagination {
                    display: none; } } }

        .swiper-container {
            @include breakpoint(768) {
                width: percentage(1200/1920);
                overflow: visible; }

            @include breakpoint(medium down) {
                padding-bottom: rem-calc(18); } }

        //  寬度
        .swiper-slide {
            opacity: 0.4;
            transition: 0.3s opacity; }

        .swiper-slide-active {
            opacity: 1; }

        .slide-banner {
            &__link {
                display: block; } }

        // arrow
        .swiper-arrow {
            transform: translateY(-50%); }

        .swiper-arrow_left {
            left: calc(50vw - 31.25% - 15px / 2 - 45px / 2);

            @include breakpoint(xxlarge) {
                left: calc(50vw - 31.25% - 15px / 2 - 60px / 2); }

            @include breakpoint(big) {
                left: calc(50vw - 31.25% - 15px / 2 - 80px / 2); }

            @include breakpoint(medium down) {
                left: -30px;
                top: calc(50% - 5px);
                background-position: rem-calc(6) 50%;
                background-size: 90%; } }

        .swiper-arrow_right {
            right: calc(50vw - 31.25% - 15px / 2 - 45px / 2);

            @include breakpoint(xxlarge) {
                right: calc(50vw - 31.25% - 15px / 2 - 60px / 2); }

            @include breakpoint(big) {
                right: calc(50vw - 31.25% - 15px / 2 - 80px / 2); }

            @include breakpoint(medium down) {
                right: rem-calc(-30);
                top: calc(50% - 5px);
                background-position: -2px 50%;
                background-size: 90%; } }

        // pagination
        .swiper-container-horizontal .swiper-pagination-bullets {
            bottom: rem-calc(20);
            @include breakpoint(medium down) {
                bottom: 0px; } }

        .embed:before {
            padding-top: percentage(235/345);

            @include breakpoint(pad) {
                padding-top: percentage(560/1200); } } }

    &-sale {
        padding: rem-calc(30) 0;

        @include breakpoint(medium down) {
            padding: rem-calc(30) $paddingSpace 17px; }

        .grid-container {
            // max-width: 1260px

            @include breakpoint(medium down) {
                padding: 0; } }

        .swiper-container {
            padding: 0 0px rem-calc(20);
            @include breakpoint(large down) {
                padding: 0 5px rem-calc(20); }
            @include breakpoint(medium down) {
                padding: 0 0px rem-calc(20); } }

        // Animation
        //  // $animateEl: '.entitle', '.title-h2', '.slideshow'

        &.js-animate {
            $animateEl: '.entitle', '.title-h2', '.slideshow';
            @each $class in $animateEl {
                $i: index($animateEl, $class);
                $i: $i;

                #{$class} {
                    opacity: 0;
                    transform: translateY(60px);
                    transition-duration: 0.7s;
                    transition-property: opacity, transform;
                    transition-delay: #{$i * 0.5s}; } } }

        &.is-animated {
            $animateEl: '.entitle', '.title-h2', '.slideshow';
            @each $class in $animateEl {
                #{$class} {
                    opacity: 1;
                    transform: translateY(0); } } } }

    &-recommend-recipe {
        @include breakpoint(medium down) {
            padding: 37px 0 30px; }

        .sec-banner {
            padding: 80px 0 60px;
            background-image: url(../images/index/banner-recommend-recipe.jpg);
            @extend %bgImage;

            @include breakpoint(medium down) {
                padding: 40px 0;
                background-position: 75% center; } }

        .grid-x > .cell {
            padding: 30px;
            background-color: rgba($white, 0.8);
            z-index: 3;

            &:nth-child(1) {
                @include breakpoint(medium down) {
                    padding: 15px;
                    margin-bottom: 20px; } }

            &:nth-child(2) {
                padding: 0; } }

        .slideshow {
            padding: 38px 31px 40px;
            @include breakpoint(large down) {
                padding-right: 15px;
                padding-left: 15px; }

            @include breakpoint(medium down) {
                padding: 10px 5px 20px; }

            &.arrow-default .swiper-arrow {
                top: calc( 220px / 2 + 48px);
                transform: translateY(-50%);

                @extend %bgImage;
                background-size: 30%;
                width: rem-calc(45);
                height: rem-calc(45);
                transform: translateY(-50%);

                @include breakpoint(large down) {
                    top: calc((50vw - 32px - 60px - 15px) / 4 + 48px);
                    width: percentage(30/484);
                    height: percentage(30/484);
                    min-width: 30px;
                    min-height: 30px;
                    background-size: 32%; }

                @include breakpoint(medium down) {
                    top: calc((100vw - 40px - 40px - 15px) / 4 + 20px);
                    background-color: $white;
                    font-size: rem-calc(14);
                    width: 30px;
                    height: 30px;

                    &_right {
                        background-position: 60% 50%; }

                    &_left {
                        background-position: 40% 50%; } } } }

        .swiper-container {
            padding: 10px 25px 50px;

            @include breakpoint(large down) {
                padding: 10px 15px 50px; }

            @include breakpoint(medium down) {
                padding-bottom: 17px; }

            .swiper-pagination-bullet {
                @include breakpoint(medium down) {
                    height: 2px; } } }

        .card__box-addwish {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0; }

        // Animation
        $animateEl: (".entitle": "b-t", ".title-h2": "b-t", ".cell:nth-child(1)": "r-l", ".cell:nth-child(2)": "l-r", ".ctrl__btn": "b-t");
        $animateKey: 0;

        &.js-animate {
            @each $class, $dir in $animateEl {
                #{$class} {
                    opacity: 0;
                    transition-duration: 0.7s;
                    transition-property: opacity, transform;
                    transition-delay: #{$animateKey * 0.5s};

                    @if $dir == "b-t" {
                        transform: translateY(60px); }
                    @else if $dir == "r-l" {
                        transform: translateX(-60px);
                        @include breakpoint(medium down) {
                            transform: translateY(60px); } }
                    @else if $dir == "l-r" {
                        transform: translateX(60px);
                        @include breakpoint(medium down) {
                            transform: translateY(60px); }

                        $animateKey: $animateKey +1; } } } }

        &.is-animated {
            @each $class, $dir in $animateEl {
                #{$class} {
                    opacity: 1;
                    transform: translateY(0); } } } }

    &-subject {
        padding-bottom: 0;
        position: relative;
        .grid-container {
            z-index: 3;
            position: relative; }

        @include breakpoint(medium down) {
            padding: 30px 0 0px; }

        .card__box {
            margin-bottom: 30px; }

        .container {
            position: relative;
            z-index: 2; }

        // 背景圖
        .sec-deco__vege {
            &-01 {
                position: absolute;
                top: rem-calc(-555);
                left: 0px;
                z-index: 0;
                width: percentage(417/1920);
                pointer-events: none;

                @include breakpoint(1900px down) {
                    top: rem-calc(-450); }

                @include breakpoint(1600px down) {
                    top: rem-calc(-400); }

                @include breakpoint(large down) {
                    top: rem-calc(-254);
                    width: 210px; }

                @include breakpoint(medium down) {
                    top: rem-calc(-125);
                    width: 105px; } }

            &-02 {
                position: absolute;
                z-index: 0;
                top: percentage(880/2771);
                right: -10px;
                pointer-events: none;
                width: 17%;

                @include breakpoint(1800px) {
                    width: percentage(414/1920);
                    top: percentage(678/2771); }

                @include breakpoint(large down) {
                    display: none; } } }

        // Animation
        .panel__box {
            &.js-animate {
                opacity: 0;
                transition-property: opacity, transform;
                transform: translateY(60px);
                @for $i from 1 through 5 {
                    transition-duration: 0.3s * $i; } }

            &.is-animated {
                opacity: 1;
                transform: translateY(0); } }

        // 瀑布流
        .grid-sizer,
        .panel__box {
            width: 100%;
            @include breakpoint(large) {
                width: calc(50% - 12px); } }

        .gutter-sizer {
            @include breakpoint(large) {
                width: rem-calc(24); } } }

    .popular-link-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: rem-calc(0 -10 -20); }

    .popular-link {
        font-size: 14px;
        color: #499537;
        display: inline-block;
        padding: rem-calc(10 25);
        border-radius: 18px;
        border: solid 1px #499537;
        margin-left: rem-calc(10);
        margin-right: rem-calc(10);
        margin-bottom: rem-calc(20); } }
// --------------------------------------------------------
/* Animate Style*/
// --------------------------------------------------------
